/*

Vamos a interceptar cada respuesta que recibe fetch.
Si la respuesta es un HTTP 401, vamos a ver si tiene el error de Token expired.
De ser así, renovamos el token y reenviamos la consulta original.

*/
interface ErrorMessage {
    error: string,
    url: string
}

interface ResfreshResposne {
    id_token: string,
    access_token: string
}

const fetchInterceptor = async (url: string, options: RequestInit = {}): Promise<Response> => {
    let response = await fetch(url, options); //Consulta original

    if (!response.ok && response.status == 401) {
        const appState = JSON.parse(localStorage.getItem('state') || "{}")
        //@ts-ignore
        const token = appState.auth.token;
        //@ts-ignore
        const refreshToken = appState.auth.refreshToken;
        const responseObject: ErrorMessage = await response.json();
        if (responseObject.error) {
            //Hay un campo error, revisamos si tiene el error del token expirado
            if (responseObject.error === "Token expired") {
                console.log("Token expirado");
                //Hacer el refresh del token
                await checkRefreshToken(responseObject, token, refreshToken, url, options);
                let originalResponse = await fetch(url, options);
                console.log("Se ejecutó nuevamente la solicitud original.");
                return originalResponse;
            }
        }

    }
    return response;
};


export default fetchInterceptor

async function checkRefreshToken(responseObject: any, token: string | null, refreshToken: string | null, url: string, options: RequestInit) {
    await fetch(responseObject.url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 'token': token, 'refresh_token': refreshToken })
    }).then(async (responseRefresh) => {
        console.log("Respuesta del refresh token");
        console.log(responseRefresh);

        if (responseRefresh.ok) {
            //Respuesta del refresh, reemplazar el token actual
            const refreshTokenResponse: ResfreshResposne = await responseRefresh.json();

            console.log(refreshTokenResponse);

            localStorage.setItem('token', refreshTokenResponse.id_token);
            localStorage.setItem('accessToken', refreshTokenResponse.access_token);
        }
        else {
            localStorage.clear(); //Eliminar informacón de login
            window.location.href = "/";
        }
    });
}
