import React from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import Library from './components/Library';
import Profile from './components/Profile';
import TopAppBar from './components/TopAppBar'
import TermsAndConditions from './components/TermsAndConditions'
import PrivacyPolicy from './components/PrivacyPolicy'

import { Box } from '@mui/material';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/library" element={<Library />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/tds" element={<TermsAndConditions />} /> 
        <Route path="/privacidad" element={<PrivacyPolicy />} />
      </Routes>
      {
        /* El componente Outlet será remplazado por cada componente especificado en las Routes */
      }
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
        <TopAppBar />
        <Outlet />
      </Box>
    </Router>
  );
}

export default App;
