import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Container sx={{ my: 5, mt: 10 }}>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: "#FFFFFF" }}>
          Política de Privacidad
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom sx={{ color: "#FFFFFF" }}>
          Última actualización: 5 de septiembre de 2024
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          1. Información que recopilamos
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Recopilamos únicamente el <strong>correo electrónico</strong> de los usuarios al registrarse en la aplicación. Esta información es esencial para permitir la creación de cuentas y el acceso a funcionalidades personalizadas dentro de la app.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          2. Uso de la información
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          El correo electrónico que recopilamos se utiliza para:
        </Typography>
        <ul>
          <li style={{ color: "#FFFFFF" }}>Crear y administrar tu cuenta.</li>
          <li style={{ color: "#FFFFFF" }}>Permitir el acceso a funcionalidades personalizadas, como la biblioteca de CSGs.</li>
          <li style={{ color: "#FFFFFF" }}>Enviarte actualizaciones o comunicaciones relacionadas con tu cuenta.</li>
        </ul>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          3. Almacenamiento de la información
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Tu información es almacenada de forma segura en servidores de <strong>AWS</strong> (Amazon Web Services). Cumplimos con las normativas de seguridad de datos a nivel mundial y usamos <strong>AWS Cognito</strong> para la autenticación y protección de las cuentas de usuario.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          4. Uso de servicios de terceros
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Utilizamos servicios de terceros únicamente para mejorar la experiencia del usuario:
        </Typography>
        <ul>
          <li style={{ color: "#FFFFFF" }}><strong>Google Analytics</strong>: Para el seguimiento y análisis de las interacciones de los usuarios con la aplicación. Estos datos se utilizan para mejorar el servicio, sin recopilar información personal más allá del correo electrónico.</li>
          <li style={{ color: "#FFFFFF" }}><strong>AWS</strong>: Para el almacenamiento seguro de los datos.</li>
        </ul>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          5. No compartimos tus datos
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          No compartimos tu información personal con terceros, excepto cuando sea necesario para cumplir con leyes o regulaciones aplicables.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          6. Seguridad
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Adoptamos medidas de seguridad técnicas y organizativas para proteger tu información. Usamos <strong>AWS Cognito</strong> para gestionar la autenticación y garantizar que solo tú tengas acceso a tu cuenta.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          7. Derechos del usuario
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Como usuario, tienes derecho a:
        </Typography>
        <ul>
          <li style={{ color: "#FFFFFF" }}>Acceder a tu información.</li>
          <li style={{ color: "#FFFFFF" }}>Rectificar cualquier error en tu correo electrónico.</li>
          <li style={{ color: "#FFFFFF" }}>Solicitar la eliminación de tu cuenta.</li>
        </ul>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          8. Cambios en esta política
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Podemos actualizar esta política de privacidad periódicamente. Cualquier cambio será notificado a través de la app o por correo electrónico.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          9. Contacto
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Si tienes preguntas sobre nuestra política de privacidad, puedes contactarnos en soporte@daia.cl.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
