import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return {};
      }
      return JSON.parse(serializedState);
    } catch (err) {
      return err;
    }
  };
  
const saveState = (state: RootState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};
interface StoreState {
  auth: ReturnType<typeof authReducer>;
}

const store = configureStore<StoreState>({
  reducer: {
    auth: authReducer,
  },
  preloadedState: loadState(),
});
store.subscribe(() => {
  saveState(store.getState());
});

export { store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
