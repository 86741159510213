import React, { useEffect, useState } from 'react';
import { TextField, Button, Box, Typography, IconButton, InputAdornment, Alert, Modal, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)({
  backgroundColor: '#0057B8',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#003865',
  },
});

const FullHeightBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#003865',
  minHeight: '100vh', // Asegura que el Box ocupe toda la altura de la vista
  paddingTop: '64px', // Espacio para el AppBar
});

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [timer, setTimer] = useState(240);
  const [canResend, setCanResend] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/login');
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar;
  };

  const handleRegister = async (event: React.FormEvent) => {
    event.preventDefault();
    let validationError = '';
    if (!validateEmail(email)) {
      validationError = 'Por favor, ingresa un correo electrónico válido';
    } else if (password !== repeatPassword) {
      validationError = 'Las contraseñas no coinciden';
    } else if (!validatePassword(password)) {
      validationError = 'La contraseña debe tener al menos 8 caracteres, incluir una minúscula, una mayúscula, un número y un símbolo';
      return;
    }
    if (validationError) {
      setError(validationError);
    } else {
      setError('');
      setIsConfirming(true);
      setLoading(true);

      try {
        const response = await fetch('/api/v1/public/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });

        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.error || 'Error en el registro');
        }

        setLoading(false);
        //setIsConfirming(true); // Mostrar el formulario de confirmación
      } catch (error: any) {
        setLoading(false);
        setError(error.message);
      }
    }
  };

  const handleConfirm = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('/api/v1/public/validate_user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, confirmationCode })
      });
      const data = await response.json();
      if (response.ok) {
        setLoading(false);
        setShowModal(true);

      }
      else {
        setLoading(false);
        setError(data.error)
      }
    } catch (error) {
      setLoading(false);
      setError('Error en la solicitud de confirmación')
    }

  };

  const handleResendCode = async () => {
    setLoading(true);
    setCanResend(false);
    setTimer(240);

    try {
      const response = await fetch('/api/v1/public/resend-confirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Error al reenviar el código de confirmación');
      }

      setLoading(false);
      alert('Código de confirmación reenviado');
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Box>
      <FullHeightBox>
        {!isConfirming ? (
          <>
            <Typography variant="h1" sx={{ fontSize: { xs: '2rem', md: '3rem' }, textAlign: 'center', mb: 2, color: '#FFFFFF' }}>
              Regístrate
            </Typography>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                maxWidth: '400px',
                backgroundColor: '#FFFFFF',
                boxShadow: 1,
                borderRadius: 1,
                p: 3,
                mt: 2
              }}
              onSubmit={handleRegister}
            >
              <TextField
                label="Correo"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (!validateEmail(e.target.value)) {
                    setError('Correo electrónico no válido');
                  } else if (error === 'Correo electrónico no válido') {
                    setError('');
                  }
                }}
                error={!!error && error.includes('Correo electrónico')}
                helperText={error && error.includes('Correo electrónico') ? 'Correo electrónico no válido' : ''}
                sx={{ mb: 2 }} />
              <TextField
                label="Contraseña"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                required
                sx={{ mb: 2 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
              <TextField
                label="Repetir Contraseña"
                type={showRepeatPassword ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                required
                sx={{ mb: 2 }}
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowRepeatPassword}>
                        {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              <StyledButton variant="contained" fullWidth type="submit">
                Registrarse
              </StyledButton>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h1" sx={{ fontSize: { xs: '2rem', md: '3rem' }, textAlign: 'center', mb: 2, color: '#FFFFFF' }}>
              Confirmar Código
            </Typography>
            <Box
              component="form"
              sx={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'background.paper',
                boxShadow: 1,
                borderRadius: 1,
                p: 3,
              }}
              onSubmit={handleConfirm}
            >
              <TextField
                label="Código de Confirmación"
                variant="outlined"
                fullWidth
                required
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                disabled={loading}
              />
              {error && <Alert severity="error" style={{ marginTop: '10px' }}>{error}</Alert>}
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '10px' }}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Confirmar'}
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleResendCode}
                  disabled={!canResend}
                >
                  {canResend ? 'Reenviar código' : `Reenviar código en ${formatTime(timer)}`}
                </Button>
              </Box>
            </Box>
          </>
        )}



      </FullHeightBox>
      <Modal open={showModal} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#003865', // Fondo del modal
            borderRadius: 1,
            boxShadow: 24,
            p: 4,
            color: '#FFFFFF' // Texto blanco
          }}
        >
          <Typography variant="h6" component="h2" sx={{ textAlign: 'center', mb: 2 }}>
            ¡Felicitaciones!
          </Typography>
          <Typography sx={{ textAlign: 'center', mb: 2 }}>
            Te has registrado con éxito.
          </Typography>
          <Button variant="contained" color="primary" fullWidth onClick={handleModalClose}>
            Iniciar sesión
          </Button>
        </Box>
      </Modal>
    </Box>



  );
};

export default Register;
