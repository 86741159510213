import React, { useEffect, useState } from "react";
import {Container,Button,Grid,Typography,Link} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "@mui/icons-material/Google";
import { useAppDispatch } from "../redux/hooks";
import { login } from "../redux/authSlice";
import { Link as RouterLink } from 'react-router-dom';
import MicrosoftIcon from '@mui/icons-material/Microsoft'; 

const FullHeightGrid = styled(Grid)({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1.5),
}));

const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [userNotConfirmed, setUserNotConfirmed] = useState(false);
  const [timer, setTimer] = useState(240); // 4 minutos en segundos
  const [canResend, setCanResend] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

 //Para el login con Google, devuelve el grant y se usa
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const codeGrant = params.get("code");
    if (codeGrant != null) {
      setLoading(true);
      fetch("/api/v1/public/oauth/grant?code=" + codeGrant, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.ok) {
          result.json().then((tokenJson) => {
            const jwt = tokenJson["id_token"];
            const accessToken = tokenJson["access_token"];
            const email = tokenJson["email"];
            const refresh_token = tokenJson["refresh_token"];
            console.log('refresh token UseEffect: ' + refresh_token);
            if (jwt != null) {
              dispatch(
                login({
                  token: jwt,
                  accessToken: accessToken,
                  email: email,
                  refreshToken: refresh_token,
                })
              );
              navigate("/");
            }
          });
        }
        setLoading(false);
      });
        // Borra los parámetros de la URL
      const newUrl = window.location.pathname;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch("/api/v1/public/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.success) {
        const id_token = data.id_token;
        const access_token = data.access_token;
        const email2 = data.email;
        const refresh_token = data.refresh_token;
        alert("Inicio de sesión exitoso");
        dispatch(
          login({
            token: id_token,
            accessToken: access_token,
            email: email2,
            refreshToken: refresh_token,
          })
        );
        navigate("/");
      } else if (data.status === "USER_NOT_CONFIRMED") {
        alert(
          "Tu cuenta no está confirmada. Hemos reenviado el código de confirmación a tu correo."
        );
        setUserNotConfirmed(true);
      } else {
        alert("Inicio de sesión fallido:" + data.message + data.status);
      }
    } catch (err) {
      console.error("Error:", err);
      alert("Ocurrió un error al intentar iniciar sesión.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FullHeightGrid container>
      <Container maxWidth="xs"
      >
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2rem", md: "3rem" },
            textAlign: "center",
            mb: 2,
            color: "#FFFFFF",
          }}
        >
          Bienvenido
        </Typography>

        {/* Botón de inicio de sesión con Google */}
        <StyledButton
          variant="outlined"
          startIcon={<GoogleIcon />}
          href="https://centinela.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://centinela.daia.cl/login&response_type=CODE&client_id=7f83n14vklca727dk7vhmr4i96&scope=email openid profile aws.cognito.signin.user.admin"
          sx={{
            mb: 2,
            backgroundColor: "#4285F4",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#20B2AA",
            },
          }}
        >
          Inicia sesión con Google
        </StyledButton>

        {/* Botón de inicio de sesión con Microsoft 
        <StyledButton
          variant="outlined"
          startIcon={<MicrosoftIcon />}
          href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=TU_CLIENT_ID&response_type=code&redirect_uri=http://localhost:3000/login&response_mode=query&scope=openid profile email&state=12345"
          sx={{
            mb: 2,
            backgroundColor: "#4285F4",
            color: "#FFF",
            "&:hover": {
              backgroundColor: "#20B2AA",
            },
          }}
        >
          Inicia sesión con Microsoft
        </StyledButton>
        */}

        {/* Opcional: Ingreso con correo como alternativa */}
        <Typography
      variant="body2"
      sx={{ mb: 2, color: "#FFFFFF", xs: 4, md: 0 }}
      textAlign="center"
    >
      Al continuar, reconoces que comprendes y aceptas los{' '}
      <Link
        component={RouterLink}
        to="/tds"
        sx={{ color: "#FFFFFF", textDecoration: 'underline' }}
      >
        Términos y condiciones
      </Link>{' '}
      y la{' '}
      <Link
        component={RouterLink}
        to="/privacidad"
        sx={{ color: "#FFFFFF", textDecoration: 'underline' }}
      >
        Política de privacidad
      </Link>.
    </Typography>
      </Container>
    </FullHeightGrid>
  );
};

export default Login;