
import React, { useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode'; //Para el token JWT
import { Button, Box, Toolbar, IconButton, Avatar, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import daiaLogo from '../assets/daia.png'; // Ruta actualizada
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { logout } from '../redux/authSlice';
import { RootState } from '../redux/store';

const StyledButton = styled(Button)({
  color: '#FFFFFF',
  '&:hover': {
    bgcolor: '#20B2AA',
    color: '#FFF',
    border: '2px solid #20B2AA'
  },
});

const FullWidthBox = styled(Box)({
  minWidth: '100%',
  backgroundColor: 'transparent',
  boxShadow: 'none',
});

interface userData {
  username: string,
  avatarImg: string //URL imagen de perfil
}

const TopAppBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token);
  const isAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);

  const [user, setUser] = useState<userData | null>(null); //Para almacenar el dato del usuario conectado  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/v1/public/logout', {
        method: 'POST',  // Asegúrate de usar POST
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ redirect_uri: 'https://centinela.daia.cl/login', logout_uri: 'http://centinela.daia.cl/login' }), // Enviando redirect_uri en el cuerpo
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al desconectar');
      }

      const data = await response.json();
      dispatch(logout());
      window.location.href = data.logout_url;
      handleClose();

    } catch (error) {
      console.error('Error en la solicitud de logout:', error);
    }
  };

  const handleSettings = () => {
    navigate('/profile')
    handleClose();
  };

  useEffect(() => {
    if (token && typeof token === 'string') {
      try {
        const decodedToken: any = jwtDecode(token);
        setUser({
          username: decodedToken.username,
          avatarImg: decodedToken.avatarImg || '',
        });
      } catch (error) {
        console.error("Error decoding token:", error);
        setUser(null);
        //Mejor desconectar el usuario en caso de error
        dispatch(logout());
      }
    } else {
      setUser(null);
    }
  }, [token]);

  return (
    <FullWidthBox position="absolute" top={0}>
      <Toolbar>
        <Box
          sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
          onClick={() => { navigate("/") }} // Maneja el clic en el ícono
          style={{ cursor: 'pointer' }} // Cambia el cursor para indicar que es clickeable
        >
          <img src={daiaLogo} alt="logo" style={{ marginRight: 16, height: 40 }} />
        </Box>
        {isAuthenticated && user ? (
          <Toolbar>
            <StyledButton variant='outlined' onClick={() => {
              navigate("/library")
            }}>Mis productores</StyledButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar alt="User Avatar" src={user.avatarImg} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSettings}>Configuración</MenuItem>
              <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            </Menu>
          </Toolbar>
        ) : (
          <StyledButton variant='outlined' onClick={() => {
            navigate("/login")
          }}>Iniciar sesión</StyledButton>
        )
        }
      </Toolbar>
    </FullWidthBox>
  )
}

export default TopAppBar;