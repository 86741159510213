import React, { useState } from 'react';
import { Grid, TextField, Button, Typography, Input, FormControl, FormLabel, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

// Estilos personalizados para los componentes
const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    color: '#000000', // Color del texto del input
  },
  '& .MuiFormLabel-root': {
    color: '#000000', // Color del label
  },
  '& .MuiInput-underline:before': {
    borderBottom: '1px solid #000000', // Línea inferior del input antes de enfocarse
  },
  '& .MuiInput-underline:after': {
    borderBottom: '2px solid #000000', // Línea inferior del input después de enfocarse
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#000000', // Color del borde del input
    },
    '&:hover fieldset': {
      borderColor: '#000000', // Color del borde del input al pasar el ratón
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000000', // Color del borde del input cuando está enfocado
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000000 !important', // Color del label para el input de tipo outlined
  },
  '& .MuiInputBase-input': {
    color: '#000000', // Color del texto en el campo de entrada
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000000', // Color del borde cuando el campo está enfocado
  },
});

const Profile: React.FC = () => {
  const [editable, setEditable] = useState<{ name: boolean; email: boolean }>({ name: false, email: false });
  const [name, setName] = useState('Felipe'); // Ejemplo de nombre de usuario
  const [email, setEmail] = useState('f.bravo@daia.cl'); // Ejemplo de correo electrónico
  const [profilePic, setProfilePic] = useState<File | null>(null); // Foto de perfil

  const handleEditClick = (field: 'name' | 'email') => {
    setEditable(prev => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSave = () => {
    // Lógica para guardar los cambios
    setEditable({ name: false, email: false });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setProfilePic(file);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: '100vh',
        px: 4,
        py: 12,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          bgcolor: '#ffffff', // Fondo blanco para el formulario
          borderRadius: 2,
          p: 4,
          boxShadow: 3,
        }}
      >
        <Grid container spacing={4} direction="column">
          <Grid item>
            <Typography variant="h4" align="center" color="#000000">
              Perfil de usuario
            </Typography>
            <Typography variant="body1" align="center" color="#666666">
              Gestione aquí la configuración de su perfil.
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                      <CustomTextField
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={!editable.name}
                        sx={{ bgcolor: '#ffffff', width: 'calc(100% - 48px)' }} // Fondo blanco para el campo de texto
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick('name')}
                        sx={{ color: '#000000' }} // Color del ícono de editar
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs>
                      <CustomTextField
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!editable.email}
                        sx={{ bgcolor: '#ffffff', width: 'calc(100% - 48px)' }} // Fondo blanco para el campo de texto
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="edit"
                        onClick={() => handleEditClick('email')}
                        sx={{ color: '#000000' }} // Color del ícono de editar
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel htmlFor="profile-picture" sx={{ color: '#000000' }}>
                    Imagen de perfil
                  </FormLabel>
                  <Input
                    id="profile-picture"
                    name="profile-picture"
                    type="file"
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleFileChange}
                    sx={{ color: '#000000', bgcolor: '#ffffff' }} // Fondo blanco para el input de archivo
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth
                  onClick={handleSave}
                  sx={{
                    bgcolor: '#0057B8',
                    color: '#FFF',
                    '&:hover': {
                      bgcolor: '#004494',
                    },
                  }}
                >
                  Guardar cambios
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
