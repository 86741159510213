import { createSlice } from '@reduxjs/toolkit';

interface AuthState {
    email: string | null;
    token: string | null;
    isAuthenticated: boolean;
    accessToken: string | null;
    refreshToken: string | null;
}

const initialState: AuthState = {
    email: null,
    token: null,
    isAuthenticated: false,
    accessToken: null, 
    refreshToken: null, 
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.token = action.payload.token;
            state.email = action.payload.email;
            state.isAuthenticated = true;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        logout: (state) => {
            state.token = null;
            state.email = null;
            state.isAuthenticated = false;
            state.accessToken = null;
            state.refreshToken = null;
        },
    },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
