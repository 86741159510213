import React, { useState, useRef } from "react";
import {
  TextField,
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Link
        } from "@mui/material";
import {Search as SearchIcon,} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";
import Result, {OmniResultType,SearchResultType,SearchRadio,} from "./Result";
import daiaHome from "../assets/daiahome.png";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import fetchInterceptor from "../utils/fetchInterceptor";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Link as RouterLink } from 'react-router-dom';


const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#FFFFFF",
  },
  "& .MuiFormLabel-root": {
    color: "#FFFFFF",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "1px solid #FFFFFF",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "2px solid #FFFFFF",
  },

  "& .MuiFormLabel-root.Mui-focused": {
    color: "#FFFFFF", // Cambia este color según tus necesidades
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
    },
  },
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Home: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Array<SearchResultType>>(
    []
  );

  const [searchRadio, setSearchRadio] = useState<SearchRadio | null>(null);
  const [omniResult, setOmniResult] = useState<OmniResultType | null>(null);
  const [loading, setLoading] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  const idToken = useAppSelector((state: RootState) => state.auth.token);
  const email = useAppSelector((state) => state.auth.email);
  const [isAddCSG, setIsAddCSG] = useState(false);
  const [isLoadAddCSG, setLoadAddCSG] = useState(false);
  const [notExistCSG, setNotExistCSG] = useState(true);

  const AddUserCSG = async () => {
    setLoadAddCSG(true);

    try {
      const response = await fetchInterceptor("/api/v1/private/add_user_csg", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          user: email,
          csg: searchQuery,
          refresh_token: refreshToken,
          id_token: idToken,
        }),
      });
      const data = await response.json();

      if (data.error) {
        setIsAddCSG(true);
      } else if (response.status === 201) {
        setIsAddCSG(true);
      }
      setLoadAddCSG(false);
    } catch (error) {
      console.error(error);
      console.log(error);
    }
  };

  const handleSearchClick = async () => {
    setSearchRadio(null);
    setIsAddCSG(false);
    setLoading(true);
    setNotExistCSG(true);

    try {
      // Reset
      setOmniResult(null);
      setSearchResults([]);

      // Descolgado
      fetchInterceptor(`/api/v1/public/grower?csg=${searchQuery}`).then(
        (response) => {
          response.json().then((data) => {
            console.log("Data received from API:", data);

            if (Array.isArray(data)) {
              setSearchResults(data);
            } else {
              setSearchResults([]);
            }
          });
        }
      );

      // Radio SAG
      fetchInterceptor(`/api/v1/public/radio?csg=${searchQuery}`).then(
        (response) => {
          response.json().then((data) => {
            console.log("RADIO RECIBIDO)", data);

            if (data) {
              setSearchRadio(data);
            } else {
              setSearchRadio(null);
            }
          });
        }
      );

      // Información general
      fetchInterceptor(
        `https://omni.daia.cl/public/sag/part?code=${searchQuery}`
      )
        .then((response) => {
          if (response.status === 200) {
            response.json().then((data) => {
              console.log("Data received from API (omni):", data);
              setOmniResult(data);
            });
          } else {
            setOpen(false);
            setNotExistCSG(false);
          }
        })
        .catch((err) => {
          setOpen(false);
          setNotExistCSG(false);
        });
      setOpen(true);

      if (isAuthenticated) {
        const checkSubscriptionResponse = await fetchInterceptor(
          "/api/v1/private/check_user_csg",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              user: email,
              csg: searchQuery,
            }),
          }
        );

        const checkSubscriptionData = await checkSubscriptionResponse.json();

        // Si ya está suscrito, mostrar mensaje y deshabilitar el botón de suscripción
        if (
          checkSubscriptionResponse.status === 200 &&
          checkSubscriptionData.subscribed
        ) {
          setIsAddCSG(true);

          return; // Salir temprano, no continuar con la búsqueda
        }
      }
    } catch (error) {
      console.log("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <>
      <Grid
            container
            spacing={2}
            sx={{
              minHeight: "calc(100vh - 50px)", // Usa minHeight para permitir flexibilidad
              color: "#FFFFFF",
              overflow: "hidden", // Prevenir scroll inesperado
              paddingBottom: { xs: "70px", md: 0 } // Reservar espacio para el footer en móviles
            }}
          >
        {/* Call to Action */}
        <Grid
          item
          xs={12}
          md={6}
          mt={8}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            px: 2,
          }}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: "3rem", md: "9rem" }, mb: 2 }}
          >
            Centinela
          </Typography>
          <Typography sx={{ fontSize: { xs: "2rem", md: "1.1rem" }, mb: 2 }}>
            by
          </Typography>
          <img
            src={daiaHome}
            alt="logo"
            style={{ marginRight: 16, height: 100 }}
          />
        </Grid>

        {/* Buscador */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" }, // Centra el contenido en dispositivos móviles
            textAlign: { xs: "center", md: "left" }, // Alinea el texto al centro en móviles
            px: 2,
            mt: { xs: 6, md: 0 }, // Aumenta el margen superior en dispositivos móviles
          }}
        >
          <Typography
              variant="h1"
              sx={{
                fontSize: { xs: "2.5rem", md: "4rem" }, // Ajuste del tamaño en móviles
              }}
            >
            Busca un CSG 
          </Typography>
          <Typography
              variant="h2"
              sx={{ 
                fontSize: { xs: "1.5rem", md: "1.2rem" }, 
                mt: { xs: 2}, // Espacio inferior más grande en dispositivos móviles
                mb: 2, // Añade margen inferior para separar los componentes
                maxWidth: { xs: "80%", md: "70%" },
                textAlign: { xs: "center", md: "left" } 
              }}
            >
             <b>Descubre si existen descuelgues de exportación de fruta en tu productor.</b> 
            </Typography>

            <CustomTextField
              fullWidth
              label="Ingresa un CSG"
              autoComplete="off"
              id="fullWidth"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleKeyDown}
              inputRef={searchInputRef}
              sx={{
                maxWidth: { xs: "80%", md: "60%" }, 
                margin: { xs: "0 auto", md: "0" }, 
                textAlign: { xs: "center", md: "left" },
              }}
            />

            {notExistCSG ? null : (
              <Typography sx={{ color: "red", textAlign: "center" }}>
                * CSG no encontrado!
              </Typography>
            )}

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              startIcon={
                loading ? <CircularProgress size={20} /> : <SearchIcon />
              }
              sx={{
                bgcolor: "#0057B8",
                color: "#FFF",
                "&:hover": {
                  bgcolor: "#20B2AA",
                  color: "#FFF",
                },
              }}
              onClick={handleSearchClick}
              disabled={loading}
            >
              {loading ? "Buscando..." : "Buscar"}
            </Button>
          </Box>
          <Typography
              variant="h2"
              sx={{ 
                fontSize: { xs: "1.5rem", md: "1.2rem" }, 
                mt: 6, // Añade margen inferior para separar los componentes
                maxWidth: { xs: "80%", md: "90%" },
                textAlign: { xs: "center", md: "left" } 
              }}
            >
             <p>Centralizamos la información de tus productores, exportadores y planta en un solo lugar 🎯</p>
             <p><b>Inicia sesión</b> y <b>suscríbe</b> tus productores a una lista de favoritos, <b>Centinela</b> te notificará por correo 📫 de cualquier hito o descuelgue existente.</p>
            </Typography>

        </Grid>
      </Grid>

      {/* Footer */}
      <Grid
            item
            component="footer"
            sx={{
              p: 2,
              textAlign: "center",
              color: "#FFFFFF",
              position: "fixed", // Cambiado a fixed
              bottom: 0,
              width: "100%",
              backgroundColor: "#003865", // Agrega un fondo para diferenciarlo del contenido
              zIndex: 1000, // Asegura que esté por encima del resto del contenido
              height: "70px", // Define la altura del footer
            }}
          >
      <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography>Centinela v0.0.3</Typography>
      </Grid>

      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <Link
              component={RouterLink}
              to="/tds"
              sx={{ color: "#FFFFFF", textDecoration: 'underline' }}
            >
              Términos y condiciones
            </Link>
          </Grid>
          <Grid item>
            <Link
              component={RouterLink}
              to="/privacidad"
              sx={{ color: "#FFFFFF", textDecoration: 'underline' }}
            >
              Política de privacidad
            </Link>
          </Grid>
        </Grid>
      </Grid>
      </Grid>
      </Grid>


      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            bgcolor: "#003865",
            color: "#FFFFFF",
            maxHeight: "80vh",
          },
        }}
      >
        <DialogTitle>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", color: "#FFFFFF" }}
          >
            Resultados de la búsqueda
          </Typography>
          <Box sx={{ position: "absolute", top: 30, right: 80 }}>
            {" "}
            {/* Contenedor para la alineación */}
            <Tooltip
              title={
                isAddCSG ? "Estás suscrito a este CSG" : "Suscríbete a este CSG"
              }
            >
              <span>
                <IconButton
                  onClick={
                    isAuthenticated ? AddUserCSG : () => navigate("/login")
                  }
                  disabled={isAddCSG}
                >
                  {isLoadAddCSG ? (
                    <CircularProgress sx={{ fontSize: 40 }} />
                  ) : (
                    <NotificationsIcon
                      sx={{
                        color: isAddCSG ? "secondary" : "white",
                        fontSize: 40,
                      }}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ 
          overflowY: "auto",
          maxHeight: { xs: "60vh", md: "80vh" }, // Limita la altura en móviles  
          }}>
          {open ? (
            <Result
              searchResults={searchResults}
              omniResult={omniResult}
              searchRadio={searchRadio}
            />
          ) : (
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              No se encontraron resultados.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Home;
