import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsAndConditions: React.FC = () => {
  return (
    <Container sx={{ my: 5, mt:10 }}>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: "#FFFFFF" }}>
          Términos y Condiciones
        </Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom sx={{ color: "#FFFFFF" }}>
          Última actualización: 5 de septiembre de 2024
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          1. Descripción del servicio
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          <strong>Centinela</strong> proporciona información sobre plagas en frutas, así como sobre el radio de detección de la mosca de la fruta y detalles de los CSGs, en el contexto del <strong>SAG Chileno</strong>. Los usuarios pueden:
        </Typography>
        <ul>
          <li style={{ color: "#FFFFFF" }}>Acceder a la información de cualquier CSG.</li>
          <li style={{ color: "#FFFFFF" }}>Al registrarse, guardar hasta 5 CSGs en su biblioteca personal.</li>
          <li style={{ color: "#FFFFFF" }}>Suscribirse para eliminar la restricción y guardar CSGs ilimitados.</li>
        </ul>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          2. Registro y suscripción
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          El uso básico de la app es gratuito. Al registrarse, los usuarios pueden acceder a funcionalidades adicionales como la biblioteca personal de CSGs. Los usuarios pueden optar por una suscripción para desbloquear el acceso ilimitado a la biblioteca de CSGs.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          3. Uso aceptable
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Al utilizar <strong>Centinela</strong>, aceptas usar la información proporcionada de manera responsable y no realizar ningún acto ilegal o perjudicial con dicha información.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          4. Limitación de responsabilidad
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          La información proporcionada en la app proviene de fuentes confiables como el <strong>SAG Chileno</strong>. Sin embargo, <strong>Centinela</strong> no se hace responsable por el uso que los usuarios hagan de la información, ni de las decisiones tomadas basándose en ella.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          5. Cancelación de cuenta
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Los usuarios pueden cancelar su cuenta en cualquier momento. La cancelación no otorga derecho a ningún reembolso en caso de suscripción activa.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          6. Modificaciones
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          <strong>Centinela</strong> se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Los cambios serán notificados a los usuarios a través de la aplicación.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          7. Jurisdicción
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Estos términos se rigen por las leyes de la República de Chile. Cualquier disputa será sometida a la jurisdicción exclusiva de los tribunales de Chile.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom sx={{ color: "#FFFFFF" }}>
          8. Contacto
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: "#FFFFFF" }}>
          Para cualquier consulta o problema, puedes contactarnos en soporte@daia.cl.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
