import React, { useState, useEffect } from "react";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  CircularProgress,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import daiaLogo from "../assets/daia.png"; // Asegúrate de que la ruta sea correcta
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import fetchInterceptor from "../utils/fetchInterceptor";

const Transition = React.forwardRef(function Transition(
  props: any,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Library: React.FC = () => {
  const [watchlist, setWatchlist] = useState<string[]>([]); // Estado para la watchlist
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false); // Estado para el diálogo de confirmación
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null); // Índice del ítem a eliminar
  const accessToken = useAppSelector((state: RootState) => state.auth.token);

  const navigate = useNavigate(); // Usa el hook useNavigate

  // Función para obtener la watchlist desde el backend
  const fetchWatchlist = async () => {
    try {
      console.log("Token Delete:" + accessToken);
      const response = await fetchInterceptor("/api/v1/private/watchlist", {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Añade el token para la autenticación
        },
      });

      if (response.ok) {
        response.json().then((data) => {
          setWatchlist(data.watchlist);
        });
      } else {
        console.error("Error al obtener la watchlist:", response.statusText);
      }
    } catch (error) {
      console.error("Error al obtener la watchlist:", error);
    }
  };

  useEffect(() => {
    fetchWatchlist(); // Llama a la función para obtener la watchlist
  }, [accessToken]); // Ejecuta el fetch cuando el token cambie

  const handleLogoClick = () => {
    navigate("/"); // Redirige a la página de inicio
  };

  const handleNotificationClick = (id: number) => {
    console.log(`Adjust notifications for item ${id}`);
  };

  // Muestra el diálogo de confirmación antes de eliminar
  const handleDeleteClick = (index: number) => {
    setDeleteIndex(index); // Guarda el índice del ítem a eliminar
    setOpenDialog(true); // Abre el diálogo de confirmación
  };

  // Confirmar eliminación
  const confirmDelete = async () => {
    if (deleteIndex !== null) {
      try {
        // Obtener el CSG específico que se desea eliminar
        const csgToDelete = watchlist[deleteIndex];

        // Realizar la solicitud DELETE al backend
        const response = await fetchInterceptor(`/api/v1/private/delete/${csgToDelete}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          // Actualizar la watchlist eliminando el item
          setWatchlist((prev) => prev.filter((_, i) => i !== deleteIndex));
          console.log(`Item ${csgToDelete} eliminado con éxito`);
        } else {
          console.error("Error al eliminar el item:", response.statusText);
        }
      } catch (error) {
        console.error("Error al eliminar el item:", error);
      }
      setOpenDialog(false); // Cierra el diálogo de confirmación
      setDeleteIndex(null); // Resetea el índice de eliminación
    }
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("Cerrar sesión");
    handleClose();
  };

  const handleSettings = () => {
    console.log("Configuración");
    handleClose();
  };

  const handleViewClick = (id: number) => {
    console.log(`View item ${id}`);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ color: "#FFFFFF" }}>
        {/* AppBar */}

        {/* Content */}
        <Grid item xs={12}>
          <Container sx={{ mt: 12 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Biblioteca
              </Typography>
              <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
                {`${watchlist.length}`}/5
              </Typography>
            </Box>
            <List>
              {watchlist.map((csg, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ListItemText primary={csg} />
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 1 }}
                      onClick={() => handleViewClick(index)}
                    >
                      Ver
                    </Button>
                    <IconButton onClick={() => handleNotificationClick(index)}>
                      <NotificationsIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(index)}>
                      <DeleteIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Container>
        </Grid>

        {/* Footer */}
        <Grid item xs={12} component="footer">
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              color: "#FFFFFF",
              position: "fixed",
              bottom: 0,
              width: "100%",
            }}
          >
            <Typography>Centinela v0.0.0</Typography>
          </Box>
        </Grid>

        {/* Dialog for confirmation */}
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenDialog(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Confirmar eliminación"}</DialogTitle>
          <DialogContent>
            <Typography>
              ¿Estás seguro de que deseas eliminar este ítem de la lista?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={confirmDelete}
              color="primary"
              autoFocus
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default Library;
