import React from "react";
import { Typography, Box, Chip, Grid, Skeleton } from "@mui/material";

interface SearchResultType {
  folio: string;
  nombre_exportador?: string;
  nombre_planta?: string;
  rut_productor?: string;
  nombre_productor?: string;
  csg: string;
  nombre_comuna?: string;
  nombre_especie?: string;
  nombre_variedad?: string;
  tipo_deteccion?: string;
  pais_deteccion?: string;
  pais_a_descolgar?: string;
  nombre_plaga?: string;
  tipo_exclusion?: string;
}

interface Fruta {
  especie: string;
  variedad: string;
}

interface OmniResultType {
  codigo: string;
  estado: "ACTIVO" | "INACTIVO";
  tipo: string;
  direccion: string;
  region: string;
  comuna: string;
  razon_social: string;
  especies: Fruta[];
  expiry: string;
}
interface SearchRadio {
  id: number;
  csp: string;
  region: string;
  comuna: string;
  campaña: string;
  fecha_ingreso: Date;
  radio: string;
}

interface ResultProps {
  searchResults: Array<SearchResultType>;
  omniResult: OmniResultType | null; //Puede no existe al abrir el dialogo
  searchRadio: SearchRadio | null;
}

const Result: React.FC<ResultProps> = ({ ...props }) => {
  const searchResults = props.searchResults;
  const omniResult = props.omniResult;
  const searchRadio = props.searchRadio;

  const agruparPorEspecie = (frutas: Fruta[]): Map<string, string[]> => {
    const especies: Map<string, string[]> = new Map();

    frutas.forEach((fruta) => {
      if (!especies.has(fruta.especie)) {
        especies.set(fruta.especie, []);
      }
      especies.get(fruta.especie)?.push(fruta.variedad);
    });

    return especies;
  };

  return (
    <>
      <Box key={-1} sx={{ p: 2, borderBottom: "1px solid #ddd" }}>
        {
          /* Resultado inscripción pagina SAG */
          omniResult && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    { title: "Productor: ", value: omniResult.razon_social },
                    { title: "Dirección: ", value: omniResult.direccion },
                  ].map(({ title, value }) => (
                    <Box
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        {title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {value ?? "N/A"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    {
                      title: "Código: ",
                      value: omniResult.codigo + " (" + omniResult.estado + ")",
                    },
                    {
                      title: "Comuna: ",
                      value: omniResult.comuna + ", " + omniResult.region,
                    },
                  ].map(({ title, value }) => (
                    <Box
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mr: 1 }}
                      >
                        {title}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {value ?? "N/A"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
              {/* Especies y varieadades inscritas */}
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Especies inscritas
                  </Typography>
                  <Grid container spacing={2} justifyContent="center">
                    {omniResult.especies &&
                      omniResult.especies.length > 0 &&
                      [...agruparPorEspecie(omniResult.especies).keys()].map(
                        (especie) => (
                          <Grid item key={especie} xs={12} sm={6} md={4} lg={3}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold" }}
                              >
                                {especie}
                              </Typography>
                              <Box sx={{ pl: 2 }}>
                                {(
                                  agruparPorEspecie(omniResult.especies).get(
                                    especie
                                  ) || []
                                ).map((variedad) => (
                                  <Typography
                                    variant="body2"
                                    gutterBottom
                                    key={variedad}
                                  >
                                    {variedad ?? "N/A"}
                                  </Typography>
                                ))}
                              </Box>
                            </Box>
                          </Grid>
                        )
                      )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )
        }
        {
          /* Skeleton mientras carga para pagina sag */
          !omniResult && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    { title: "Productor: ", value: "XXXXXXXXXXXXXXXX" },
                    { title: "RUT Productor: ", value: "XXXXXXXXXXXXXXXX" },
                  ].map(({ title, value }) => (
                    <Box
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      <Skeleton variant="rectangular" width={150} height={36} />
                      <Skeleton variant="rectangular" width={150} height={36} />
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    { title: "Productor: ", value: "XXXXXXXXXXXXXXXX" },
                    { title: "RUT Productor: ", value: "XXXXXXXXXXXXXXXX" },
                  ].map(({ title, value }) => (
                    <Box
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      <Skeleton variant="rectangular" width={150} height={36} />
                      <Skeleton variant="rectangular" width={150} height={36} />
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {[
                    { title: "Productor: ", value: "XXXXXXXXXXXXXXXX" },
                    { title: "RUT Productor: ", value: "XXXXXXXXXXXXXXXX" },
                  ].map(({ title, value }) => (
                    <Box
                      key={title}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        mb: 1,
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={100}
                      />
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          )
        }
      </Box>

      {/* Resultado Mosca de la fruta radio */}
      <Box sx={{ p: 2, borderBottom: "1px solid #ddd" }}>
        <Typography variant="h5" sx={{ textAlign: "center", color: "#FFFFFF" }}>
          Restricción Mosca de la Fruta
        </Typography>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: !searchRadio
              ? "lime"
              : searchRadio.radio === "27,2" || searchRadio.radio === "80"
              ? "yellow"
              : "red",
          }}
        >
          Radio: {searchRadio ? searchRadio?.radio : "Libre"}
        </Typography>
      </Box>

      {
        /* Resultado descolgado */
        searchResults && searchResults.length > 0 ? (
          searchResults.map((result, index) => (
            <Box key={index} sx={{ p: 2, borderBottom: "1px solid #ddd" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {[
                      { title: "Folio: ", value: result.folio },
                      {
                        title: "Exportador: ",
                        value: result.nombre_exportador,
                      },
                      { title: "Planta: ", value: result.nombre_planta },
                    ].map(({ title, value }) => (
                      <Box
                        key={title}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold", mr: 1 }}
                        >
                          {title}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {value ?? "N/A"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {[
                      { title: "Especie: ", value: result.nombre_especie },
                      { title: "Variedad: ", value: result.nombre_variedad },
                      {
                        title: "País Detección: ",
                        value: result.pais_deteccion,
                      },
                      { title: "Plaga: ", value: result.nombre_plaga },
                      { title: "Exclusión: ", value: result.tipo_exclusion },
                    ].map(({ title, value }) => (
                      <Box
                        key={title}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "100%",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: "bold", mr: 1 }}
                        >
                          {title}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {value ?? "N/A"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {result.pais_a_descolgar && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Alinea verticalmente el texto con el Chip
                        justifyContent: "center", // Centra horizontalmente el contenido
                        textAlign: "center", // Asegura que el texto esté centrado dentro de su contenedor
                      }}
                    >
                      <Typography
                        sx={{
                          marginRight: "8px", // Espacio entre el texto y el Chip
                          fontWeight: "bold",
                          color: "#FFFFFF", // Color del texto
                        }}
                      >
                        País a descolgar:
                      </Typography>
                      <Chip
                        label={result.pais_a_descolgar}
                        sx={{
                          bgcolor: "#FFA500", // Fondo del Chip
                          color: "#000000", // Color del texto en el Chip
                          fontWeight: "bold",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Box sx={{ p: 2, borderBottom: "1px solid #ddd" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              No se encontraron resultados de descuelgue.
            </Typography>
          </Box>
        )
      }
    </>
  );
};

export default Result;
export type { SearchResultType, OmniResultType, SearchRadio };
